import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUser, resetPassword } from "./AuthService";

const AuthForgot = () => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState({
    email: ""
  });

  const [add, setAdd] = useState(false);

  useEffect(() => {
    if (checkUser()) {
      navigate("/home");
    }
  }, [navigate]);

  useEffect(() => {
    if (currentUser && add) {
      resetPassword(currentUser).then(() => {
        setAdd(false);
        navigate("/login");
      });
    }
  }, [navigate, currentUser, add]);

  const onChangeHandler = (e) => {
    e.preventDefault();
    const { name, value: newValue } = e.target;
    setCurrentUser({
      ...currentUser,
      [name]: newValue
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setAdd(true);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0F1A2D]">
      <form onSubmit={onSubmitHandler} className="bg-[#1B263B] rounded-lg shadow-lg flex flex-col items-center p-8 md:w-2/3 lg:w-1/2 xl:w-1/3">
        <h1 className="text-3xl text-white mb-9">Reset Your Password</h1>

        <div className="w-full max-w-xs flex flex-col items-center">
          <label htmlFor="email" className="text-white w-full mb-2">Email</label>
          <input
            id="email"
            type="email"
            value={currentUser.email}
            onChange={onChangeHandler}
            className="w-full px-3 py-2 placeholder-gray-400 text-gray-100 bg-[#374151] rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600"
            name="email"
            required
          />
        </div>

        <div className="pt-5 w-full max-w-xs flex flex-col items-center">
          <button type="submit" className="w-full bg-[#0A1128] text-white hover:bg-gray-700 rounded-lg px-4 py-2">
            Reset Password
          </button>
        </div>

        <div className="pt-3 w-full max-w-xs flex flex-col items-center">
          <span className="text-white">
            Remembered your password? <a href="/login" className="text-blue-500">Log In</a>
          </span>
        </div>
      </form>
    </div>
  );
};

export default AuthForgot;
