import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { checkUser, checkEmailVerification } from '../Auth/AuthService';
import Parse from 'parse';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const verifyUser = async () => {
      const currentUser = Parse.User.current();

      // If no current user, redirect to login
      if (!currentUser) {
        setRedirectTo('/login');
        return;
      }

      // Check if the user is authenticated
      if (!checkUser()) {
        setRedirectTo('/login');
        return;
      }

      // Check if the email is verified
      const emailVerified = await checkEmailVerification();
      if (!emailVerified) {
        setRedirectTo('/verify');
        return;
      }

      // Check if the user is manually verified
      const manuallyVerified = currentUser.get('manuallyVerified');
      if (!manuallyVerified) {
        setRedirectTo('/wait');
        return;
      }

      // If everything is okay, set redirectTo to null (which means no redirection, component will render)
      setRedirectTo(null);
    };

    verifyUser();
  }, []);

  // Handle redirection based on the conditions
  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  // If no redirection is needed, render the component
  return <Component {...rest} />;
};

export default ProtectedRoute;
