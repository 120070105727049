import Parse from "parse";

export const getBugReports = async () => {
    const currentUser = Parse.User.current();

    if (currentUser) {
        const Bug = Parse.Object.extend("Bug");
        const query = new Parse.Query(Bug);

        query.equalTo("userId", currentUser);
        query.equalTo("resolved", false);

        try {
            const results = await query.find();
            return results;
        } catch (error) {
            console.error("Error while fetching bug reports:", error);
            return [];
        }
    } else {
        console.error("No current user found.");
        return [];
    }
};

export const postBugReport = async (title, description) => {
    const currentUser = Parse.User.current();

    if (!currentUser) {
        throw new Error("No current user found.");
    }

    const Bug = Parse.Object.extend("Bug");
    const newBug = new Bug();

    newBug.set("bugTitle", title);
    newBug.set("bugDescription", description);
    newBug.set("userId", currentUser);
    newBug.set("resolved", false);

    try {
        const result = await newBug.save();
        return result;
    } catch (error) {
        console.error("Error while creating bug report:", error);
        throw error;
    }
};

