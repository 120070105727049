import Components from "./Components/Components.js";
import Parse from "parse";

Parse.initialize(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_JAVASCRIPT_KEY);
Parse.serverURL = process.env.REACT_APP_SERVER_URL;

function App() {
    return <Components />;
}

export default App;