import React from "react";
import {
    BrowserRouter as Router,
    // Navigate,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";


import Navbar from "./Navbar/Navbar.js";
import Footer from "./Footer/Footer.js";
import Home from "./Home/Home.js";
import AuthLogin from "./Auth/AuthLogin.js";
import EmailVerify from "./Auth/EmailVerify.js";
import IndividualCompany from "./Home/IndividualCompany.js";
import Bugs from "./Bugs/Bugs.js";
import Profile from "./Profile/Profile.js";

import ProtectedRoute from "./ProtectedRoute/ProtectedRoute.js";
import AuthSignUp from "./Auth/AuthSignUp.js";
import AuthForgot from "./Auth/AuthForgot.js";
import Wait from "./Auth/Wait.js";

import PrivacyPolicy from "./Legal/PrivacyPolicy.js";

function Components() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/login" element={<AuthLogin />} />
                <Route path="/signup" element={<AuthSignUp />} />
                <Route path="/forgot" element={<AuthForgot />} />
                <Route path="/home" element={<ProtectedRoute path="/" element={Home} />} />

                <Route
                        path="/home/:companyId"
                        element={
                            <ProtectedRoute
                                path="/"
                                element={IndividualCompany}
                            />
                        }
                />

                <Route path="/profile" element={<ProtectedRoute path="/" element={Profile} />} />
                <Route path="/bugs" element={<ProtectedRoute path="/" element={Bugs} />} />
                <Route path="/verify" element={<EmailVerify />} />
                <Route path="/wait" element={<Wait />} />

                <Route path="/privacy" element={<PrivacyPolicy />} />

                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default Components;