import React, { useState, useEffect } from 'react';
import { checkUser, logoutUser } from "../Auth/AuthService.js";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userLoggedIn = checkUser();
    setIsLoggedIn(userLoggedIn);
  }, []);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await logoutUser();
    setIsLoggedIn(false);
    window.location.href = '/login'; // Redirect to login page after logout
  };

  return (
    <nav className="bg-[#1B263B] p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-white text-xl font-bold">
          <a href="/home">
            <img src="/logo.svg" alt="Logo" className="h-8" />
          </a>
        </div>

        {/* Menu for Desktop */}
        <ul className="hidden md:flex space-x-6 text-white">
          {isLoggedIn ? (
            <>
              <li className="hover:text-gray-400"><a href="/home">Home</a></li>
              <li className="hover:text-gray-400"><a href="/profile">Profile</a></li>
              <li className="hover:text-gray-400"><a target="blank_" href="https://buy.stripe.com/00gdTi3J273T45W001">Billing</a></li>
              <li className="hover:text-gray-400"><a href="/bugs">Bugs</a></li>
              <li className="hover:text-gray-400 cursor-pointer" onClick={handleLogout}>Logout</li>
            </>
          ) : (
            <>
              <li className="hover:text-gray-400"><a href="/login">Login</a></li>
              <li className="hover:text-gray-400"><a href="/signup">Sign Up</a></li>
            </>
          )}
        </ul>

        {/* Hamburger Menu Icon */}
        <div className="md:hidden text-white">
          <button className="text-2xl" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 12a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2H3zm0 5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2H3zm0-10a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H3z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>

      {/* Dropdown Menu for Mobile */}
      {isOpen && (
        <div className="md:hidden bg-gray-800">
          <ul className="space-y-2 p-4">
            {isLoggedIn ? (
              <>
                <li className="text-white hover:text-gray-400"><a href="/home">Home</a></li>
                <li className="text-white hover:text-gray-400"><a href="/profile">Profile</a></li>
                <li className="text-white hover:text-gray-400"><a href="/billing">Billing</a></li>
                <li className="text-white hover:text-gray-400"><a href="/bugs">Bugs</a></li>
                <li className="text-white hover:text-gray-400 cursor-pointer" onClick={handleLogout}>Logout</li>
              </>
            ) : (
              <>
                <li className="text-white hover:text-gray-400"><a href="/login">Login</a></li>
                <li className="text-white hover:text-gray-400"><a href="/signup">Sign Up</a></li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
