import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Parse from 'parse';

const Wait = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = Parse.User.current();

        if (user) {
            const interval = setInterval(async () => {
                const currentUser = await user.fetch(); // Fetch the latest user data
                const manuallyVerified = currentUser.get('manuallyVerified');

                if (manuallyVerified) {
                    navigate('/home');
                }
            }, 5000); // Check every 5 seconds

            return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [navigate]);

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-white min-h-screen items-center justify-center">
            <div className="bg-gray-800 p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2 text-center">
                <h1 className="text-3xl font-bold mb-4">Please Wait</h1>
                <p className="text-lg mb-4">
                    We are approving your application. Please come back later once your account has been verified.
                </p>
            </div>
        </div>
    );
};

export default Wait;
