import Parse from "parse";
// Create the user
export const createUser = (newUser) => {
  const user = new Parse.User();

  user.set("username", newUser.email);
  user.set("firstName", newUser.firstName);
  user.set("lastName", newUser.lastName);
  user.set("password", newUser.password);
  user.set("email", newUser.email);
  user.set("apiKey", newUser.apiKey);
  user.set("marketArea", newUser.marketArea);
  user.set("maxTicket", newUser.maxTicket);
  user.set("vcName", newUser.vcName);
  user.set("linkedinLink", newUser.linkedinLink);
  user.set("minTicket", newUser.minTicket);
  user.set("manuallyVerified", true);

  return user
    .signUp()
    .then((newUserSaved) => {
      return newUserSaved;
    })
    .catch((error) => {
      alert(`Error: ${error.message}`);
    });
};

// Login the user
export const loginUser = (currUser) => {
  return Parse.User.logIn(currUser.email, currUser.password)
    .then((currUserSaved) => {
      return currUserSaved;
    })
    .catch((error) => {
      alert(`Error: ${error.message}`);
    });
};

// Check the user
export const checkUser = () => {
  const currentUser = Parse.User.current();
  return currentUser !== null;
};

export const checkEmailVerification = async () => {
  const currentUser = Parse.User.current();
  if (currentUser) {
    try {
      // Fetch the latest user data from the server
      await currentUser.fetch();
      const emailVerified = currentUser.get('emailVerified');
      return emailVerified === true;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return false;
    }
  }
  console.log('No current user found.');
  return false;
};



// Logout the user
export const logoutUser = () => {
  return Parse.User.logOut();
};

// Check that the current access token isn't being used
export const checkToken = async (token) => {
  const query = new Parse.Query(Parse.User);
  query.equalTo("apiKey", token);
  const results = await query.find();
  return results.length === 0;
};


// Reset the password
export const resetPassword = (currUser) => {
    return Parse.User.requestPasswordReset(currUser.email)
    .then(() => {
        // password reset request was sent successfully
        alert(`An email was sent to ${currUser.email}`);
    }).catch((error) => {
        alert("Error: " + error.code + " " + error.message);
    });
};


export const sendApiKey = async (newApiKey) => {
  const email = Parse.User.current().get('email');
  const endpoint = process.env.REACT_APP_API_ENDPOINT + '/send-api-key';


  const payload = {
    email: email,
    newApiKey: newApiKey,
  };

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    console.log('Success:', data);
  } catch (error) {
    console.error('Error:', error);
  }
};

