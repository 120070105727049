import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser, checkUser } from "./AuthService";

export default function AuthLogin() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (checkUser()) {
      navigate('/home');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginUser(formData);
      window.location.reload(); // Reload the page to update the Navbar
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0F1A2D]">
      <form onSubmit={handleSubmit} className="bg-[#1B263B] rounded-lg shadow-lg flex flex-col items-center p-8 md:w-2/3 lg:w-1/2 xl:w-1/3">
        <h1 className="text-3xl text-white mb-9">Sign into your Account</h1>

        <div className="w-full max-w-xs flex flex-col items-center">
          <label htmlFor="email" className="text-white w-full mb-2">Email</label>
          <input
            id="email"
            type="text"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 placeholder-gray-400 text-gray-100 bg-[#374151] rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        <div className="pt-5 w-full max-w-xs flex flex-col items-center">
          <label htmlFor="password" className="text-white w-full mb-2">Password</label>
          <input
            id="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 placeholder-gray-400 text-gray-100 bg-[#374151] rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        <div className="pt-3 w-full max-w-xs flex flex-col items-end">
          <a href="/forgot" className="text-blue-500">Forgot Password?</a>
        </div>

        <div className="pt-5 w-full max-w-xs flex flex-col items-center">
          <button type="submit" className="w-full bg-[#0A1128] text-white hover:bg-gray-700 rounded-lg px-4 py-2">
            Log In
          </button>
        </div>

        <div className="pt-3 w-full max-w-xs flex flex-col items-start">
          <span className="text-white">Don't have an account? <a href="/signup" className="text-blue-500">Sign Up</a></span>
        </div>
      </form>
    </div>
  );
}
