import Parse from 'parse';

export const updateProfile = async (profileChanges) => {
    const user = Parse.User.current();
    if (!user) {
        throw new Error('No current user found.');
    }

    // Update the user fields with the profileChanges
    if (profileChanges.linkedin) user.set('linkedinLink', profileChanges.linkedin);
    if (profileChanges.minTicketSize) user.set('minTicket', Number(profileChanges.minTicketSize));
    if (profileChanges.maxTicketSize) user.set('maxTicket', Number(profileChanges.maxTicketSize));

    try {
        await user.save();
        console.log('Profile updated successfully.');
        return true;
    } catch (error) {
        console.error('Error while updating profile:', error);
        throw error;
    }
};
