import React, { useState, useEffect } from 'react';
import { getIndividualCompanyData, gatherInformation } from './HomeService.js';
import { useLocation, useNavigate } from 'react-router-dom';
// import { tab } from '@testing-library/user-event/dist/cjs/convenience/tab.js';

const IndividualCompany = () => {
    const location = useLocation();
    const currentUrl = location.pathname.replace('/home/', '');
    const fullUrl = `https://${currentUrl}`;
    const navigate = useNavigate();

    const [companyName, setCompanyName] = useState('');
    const [companyURL, setCompanyURL] = useState('');
    const [dateSourced, setDateSourced] = useState('');
    const [tags, setTags] = useState([]);
    const [tabs, setTabs] = useState([
        {
            title: '',
            blocks: [],
        },
    ]);

    const [activeTab, setActiveTab] = useState(0);
    const [activeSubTab, setActiveSubTab] = useState({});

    useEffect(() => {
        const loadCompanyData = async () => {
            try {
                const result = await getIndividualCompanyData(fullUrl);
                setCompanyName(result.company_name ? result.company_name : '');
                setCompanyURL(result.base_url ? result.base_url : '');
                setTags(result.tags ? result.tags : []);

                setDateSourced(result.date_sourced ? new Date(result.date_sourced * 1000).toLocaleDateString() : '');

                const transformedTabs = result.tab_list.map(tab => ({
                    title: tab.title,
                    blocks: tab.block_list.map(block => ({
                        blockTitle: block.block_title,
                        subBlocks: block.sub_block_list.map(subBlock => ({
                            subBlockTitle: subBlock.sub_block_title,
                            link: subBlock.link,
                            columns: subBlock.columns,
                            content: subBlock.content,
                        }))
                    }))
                }));

                setTabs(transformedTabs);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        loadCompanyData();
    }, [fullUrl]);

    const getActiveSubTabIndex = (blockIndex) => {
        return activeSubTab[blockIndex] || 0;
    };

    const handleSubTabClick = (blockIndex, subBlockIndex) => {
        setActiveSubTab({
            ...activeSubTab,
            [blockIndex]: subBlockIndex,
        });
    };

    const [copySuccess, setCopySuccess] = useState(null);

    const copyBlockContent = (blockIndex) => {
        const activeSubBlockIndex = getActiveSubTabIndex(blockIndex);
        const content = tabs[activeTab].blocks[blockIndex].subBlocks[activeSubBlockIndex].content;

        navigator.clipboard.writeText(content).then(() => {
            setCopySuccess(blockIndex); // Indicate which block's content was copied
            setTimeout(() => setCopySuccess(null), 2000); // Clear the success state after 2 seconds
        });
    };


    const calculateRows = (text) => {
        const lines = text.split('\n').length;
        const avgCharsPerRow = 40;
        const approxRowCount = Math.ceil(text.length / avgCharsPerRow);
        return Math.max(lines, approxRowCount);
    };

    const calculateExactHeight = (text) => {
        const tempElement = document.createElement("textarea");
        tempElement.style.position = "absolute";
        tempElement.style.visibility = "hidden";
        tempElement.style.height = "auto";
        tempElement.style.width = "100%"; // Matches the textarea's container width
        tempElement.style.fontFamily = "inherit";
        tempElement.style.fontSize = "inherit";
        tempElement.style.lineHeight = "inherit";
        tempElement.style.whiteSpace = "pre-wrap";
        tempElement.value = text;
    
        document.body.appendChild(tempElement);
        const height = tempElement.scrollHeight;
        document.body.removeChild(tempElement);
    
        return height;
    };
    

    return (
        <div className="bg-[#0F1A2D] min-h-screen flex flex-col text-white">
            <div className="bg-[#0A1128] pt-4 pb-8 px-8 relative">
                <div className="flex flex-wrap lg:flex-nowrap pb-16 space-y-6 lg:space-y-0">
                    <div className="w-full lg:w-1/4 ml-24">
                        <h1 className="text-white text-[64px] font-bold mb-2">{companyName}</h1>
                        <a href={companyURL} target="_blank" rel="noreferrer" className="text-[#60A5FA] text-[16px] no-underline">
                            {companyURL}
                        </a>
                        <hr className="border-t border-[#D1D5DB] my-4" />
                        <p className="text-[#718096] text-[14px] mt-2">Date Sourced</p>
                        <p className="text-[#D1D5DB] text-[14px]">{dateSourced}</p>
                    </div>

                    

                    <div className="w-full lg:w-1/2 ml-auto mr-24 bg-[#0F1A2D] rounded-lg p-6 mt-4 lg:mt-0 lg:ml-auto">
                        <h2 className="text-white text-lg font-semibold mb-4">Market Areas</h2>
                        <div className="flex flex-wrap gap-3">
                            {tags.map((tag, index) => (
                                <span key={index} className="bg-[#1A2238] text-white text-sm px-4 py-1 rounded-full inline-block">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 right-0 flex space-x-4 mr-4 ml-4 text-white">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveTab(index)}
                            className={`relative text-sm sm:text-lg pb-1 sm:pb-2 px-2 sm:px-4 pt-1 sm:pt-2 transition-colors duration-300 ${
                                activeTab === index
                                    ? 'bg-[#0F1A2D] border border-[#D1D5DB] border-b-0 rounded-t-lg'
                                    : 'text-[#718096] hover:text-white hover:bg-[#1A2238] hover:border-t hover:border-l hover:border-r hover:border-[#D1D5DB] hover:rounded-t-lg'
                            }`}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
            </div>

            <div className="ml-[7%] mr-[7%] mt-20 mb-20 space-y-28">
                <div className="flex justify-center items-center space-x-6">
                    <button
                        onClick={() => navigate('/home')}
                        className="bg-[#0A1128] text-[#E5E7EB] border border-[#D1D5DB] w-48 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-[#34495E] transition-colors"
                    >
                        Go Back
                    </button>
                    <button
                        onClick={() => {
                            if (window.confirm('Are you sure you want to regather the data?')) {
                                gatherInformation(companyURL);
                            }
                        }}
                        className="bg-[#0A1128] text-[#E5E7EB] border border-[#D1D5DB] w-48 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-[#34495E] transition-colors"
                    >
                        Regather Data
                    </button>
                </div>




                {tabs[activeTab].blocks.map((block, blockIndex) => (
                    <div
                        key={blockIndex}
                        className="relative bg-[#0A1128] border border-[#D1D5DB] rounded-lg p-6"
                    >
                        <div className="absolute -top-[40px] left-4 flex space-x-2 flex-wrap">
                            {block.subBlocks.map((subBlock, subBlockIndex) => (
                                <button
                                    key={subBlockIndex}
                                    onClick={() => handleSubTabClick(blockIndex, subBlockIndex)}
                                    className={`relative text-xs sm:text-sm md:text-lg h-10 px-2 sm:px-4 pt-1 transition-colors duration-300 ${
                                        getActiveSubTabIndex(blockIndex) === subBlockIndex
                                            ? 'bg-[#0A1128] border-l border-r border-t border-[#D1D5DB] rounded-t-lg text-white'
                                            : 'text-[#718096] hover:text-white hover:bg-[#1A2238] hover:border-t hover:border-l hover:border-r hover:border-[#D1D5DB] hover:rounded-t-lg'
                                    }`}
                                    style={{
                                        marginBottom: getActiveSubTabIndex(blockIndex) === subBlockIndex ? '-1px' : '0',
                                        borderBottom: 'none',
                                    }}
                                >
                                    {subBlock.subBlockTitle}
                                </button>
                            ))}
                        </div>

                        <h2 className="text-white text-xl font-semibold mb-2 mt-2">{block.blockTitle}</h2>

                        <a
                            href={block.subBlocks[getActiveSubTabIndex(blockIndex)].link}
                            target="_blank"
                            rel="noreferrer"
                            className="text-[#60A5FA] text-[16px] no-underline"
                        >
                            {block.subBlocks[getActiveSubTabIndex(blockIndex)].link}
                        </a>

                        {block.subBlocks[getActiveSubTabIndex(blockIndex)].columns.length > 0 && (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 mb-4">
                                {block.subBlocks[getActiveSubTabIndex(blockIndex)].columns.map((column, i) => (
                                    <div key={i} className="bg-[#1C2A3E] p-4 rounded-lg">
                                        <p className="text-[#60A5FA] font-bold mb-2">{column[0]}</p>
                                        <textarea
                                            className="text-[#E5E7EB] bg-transparent border-none resize-none w-full"
                                            readOnly
                                            value={column[1]}
                                            rows={calculateRows(column[1])}
                                            style={{ overflow: 'hidden' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        <button
                            className={`absolute top-4 right-2 px-3 py-1 rounded transition-colors ${
                                copySuccess === blockIndex ? 'bg-gray-500' : 'bg-[#494949] hover:bg-[#3A3A3A]'
                            } text-white`}
                            onClick={() => copyBlockContent(blockIndex)}
                        >
                            {copySuccess === blockIndex ? 'Copied!' : 'Copy'}
                        </button>


                        {block.subBlocks.length > 0 && (
                            <textarea
                                className="text-[#D1D5DB] mt-4 bg-transparent border-none resize-none w-full"
                                readOnly
                                value={block.subBlocks[getActiveSubTabIndex(blockIndex)].content}
                                style={{
                                    overflow: 'hidden',
                                    height: `${calculateExactHeight(block.subBlocks[getActiveSubTabIndex(blockIndex)].content)}px`,
                                }}
                            />
                        )}

                    </div>
                ))}
            </div>
        </div>
    );
};

export default IndividualCompany;
